<template>
  <v-dialog
    persistent
    v-model="profileDialogChangePassword"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">{{ texts.userprofile.dialogChangePassword.textTitle }}</p>
      <div class="display-flex align-items-center justify-content-center">
        <img
          width="90px"
          src="@/assets/icons/key.png"
          alt=""
        >
      </div>
      <div class="mt-9">
        <v-text-field
          v-model="currentPassword"
          name="password"
          :label="texts.userprofile.dialogChangePassword.currenPassword"
          :append-icon="bShowCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (bShowCurrentPassword = !bShowCurrentPassword)"
          :type="bShowCurrentPassword ? 'text' : 'password'"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.userprofile.dialogChangePassword.currenPassword"
          persistent-placeholder
          autocomplete="new-password"
        >
        </v-text-field>
        <v-text-field
          v-model="newPassword"
          name="password"
          :label="texts.userprofile.dialogChangePassword.newPassword"
          :append-icon="bShowNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (bShowNewPassword = !bShowNewPassword)"
          :type="bShowNewPassword ? 'text' : 'password'"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.userprofile.dialogChangePassword.newPassword"
          persistent-placeholder
          autocomplete="new-password"
        >
        </v-text-field>
        <v-text-field
          v-model="confirmNewPassword"
          name="password"
          :label="texts.userprofile.dialogChangePassword.confirmNewPassword"
          :append-icon="bShowConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (bShowConfirmNewPassword = !bShowConfirmNewPassword)"
          :type="bShowConfirmNewPassword ? 'text' : 'password'"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.userprofile.dialogChangePassword.confirmNewPassword"
          persistent-placeholder
          autocomplete="new-password"
        >
        </v-text-field>
      </div>
      <div
        v-if="isResponsive"
        class="mt-6"
      >
        <v-btn
          elevation="0"
          @click="saveChanges"
          :loading="bLoading"
          class="button-primary mon-bold"
          style="width: 100%;"
        >
          {{ texts.userprofile.dialogChangePassword.buttonSave }}
        </v-btn>
        <v-btn
          elevation="0"
          @click="closeDialog"
          class="button-secondary mon-regular mt-5"
          style="width: 100%;"
        >
          {{ texts.userprofile.dialogChangePassword.buttonCancel }}
        </v-btn>
      </div>
      <div
        v-else
        class="mt-6 display-flex align-items-center"
      >
        <v-btn
          elevation="0"
          @click="closeDialog"
          class="button-secondary mon-regular"
          style="width: 45%;"
        >
          {{ texts.userprofile.dialogChangePassword.buttonCancel }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          @click="saveChanges"
          :loading="bLoading"
          class="button-primary mon-bold"
          style="width: 45%;"
        >
          {{ texts.userprofile.dialogChangePassword.buttonSave }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "UserProfileDialogChangePasswordLayout",
  data() {
    //VARIABLES
    return {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      bShowCurrentPassword: false,
      bShowNewPassword: false,
      bShowConfirmNewPassword: false,
      screenWidth: 0,
      texts: "",
      bLoading: false,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.texts = FILE.userprofile[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 35;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    closeDialog: function () {
      this.$store.commit("setProfileDialogChangePassword", false);
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmNewPassword = "";
    },
    saveChanges: function () {
      this.bLoading = true;

      const payload = {
        sCurrentPassword: this.currentPassword,
        sNewPassword: this.newPassword,
        sConfirmNewPassword: this.confirmNewPassword,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/users/${this.$store.state.sUserId}/password`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    // return create contact dialog state
    profileDialogChangePassword() {
      return this.$store.state.profileDialogChangePassword;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    profileDialogChangePassword() {
      if (this.profileDialogChangePassword) {
        this.texts = FILE.userprofile[this.selectLanguage];
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.userprofile[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 20px;
}

.text-description {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}
</style>